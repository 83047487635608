// var data = [
//   "SCHILKE_2018110508440802.jpg",
//   "SCHILKE_2018110508452702.jpg",
//   "SCHILKE_2018110508573602.jpg",
//   "SCHILKE_2018110508582202.jpg",
//   "SCHILKE_2018110508595702.jpg",
//   "SCHILKE_2018110509022102.jpg",
//   "SCHILKE_2018110509102802.jpg",
//   "SCHILKE_2018110509131602.jpg",
//   "SCHILKE_2018110509183802.jpg",
//   "SCHILKE_2018110509185602.jpg",
//   "SCHILKE_2018110509204402.jpg",
//   "SCHILKE_2018110509230202.jpg",
//   "SCHILKE_2018110509245802.jpg",
//   "SCHILKE_2018110509463302.jpg",
//   "SCHILKE_2018110509512302.jpg",
//   "SCHILKE_2018110509574102.jpg",
//   "SCHILKE_2018110510030402.jpg",
//   "SCHILKE_2018110510104202.jpg",
//   "SCHILKE_2018110510135902.jpg",
//   "SCHILKE_2018110510254202.jpg",
//   "SCHILKE_2018110510463502.jpg",
//   "SCHILKE_2018110510464302.jpg",
//   "SCHILKE_2018110510501302.jpg",
//   "SCHILKE_2018110510565802.jpg",
//   "SCHILKE_2018110511165302.jpg",
//   "SCHILKE_2018110511321102.jpg",
//   "SCHILKE_2018110511402002.jpg",
//   "SCHILKE_2018110511585802.jpg",
//   "SCHILKE_2018110512014802.jpg",
//   "SCHILKE_2018110512035602.jpg",
//   "SCHILKE_2018110512155802.jpg",
//   "SCHILKE_2018110512412602.jpg",
//   "SCHILKE_2018110512584002.jpg",
//   "SCHILKE_2018110513054402.jpg",
//   "SCHILKE_2018110513360002.jpg",
//   "SCHILKE_2018110513442402.jpg",
//   "SCHILKE_2018110513591302.jpg",
//   "SCHILKE_2018110514371802.jpg",
//   "SCHILKE_2018110514383002.jpg",
//   "SCHILKE_2018110514430602.jpg",
//   "SCHILKE_2018110514551102.jpg",
//   "SCHILKE_2018110515283102.jpg",
//   "SCHILKE_2018110516094802.jpg",
//   "SCHILKE_2018110516140102.jpg",
//   "SCHILKE_2018110516223302.jpg"
// ];

var data = [];

for (let i = 1; i < 29; i++) {
	data.push('tf_2021_' + i + '.jpg')
}


if (document.getElementById("mainImage")) {
  var slideshow = $("#mainImage");
  var slidesArray = $("#mainImage .container .image");
  var thumbs = document.getElementById("thumbs");
  var container = $(".container");
  var outputImages = "";
  var current = 1;
  var output = "";

  for (var i = 0; i < data.length; i++) {
    output += `<div class="thumb" data-target="${i}" style="background: url(assets/img/momente/2020/${
      data[i]
    }) center; background-size: cover"></div>`;

    thumbs.innerHTML = output;
  }

  for (var i = 0; i < data.length; i++) {
    outputImages += `
    <div class="image-${i} image active" id="no${i}" style="background: url('assets/img/momente/2020/${
      data[i]
    }') center; background-size: cover"></div>`;
    container.html(outputImages);
  }

  const timeOut = function() {
    current >= data.length ? (current = 1) : current++;
    $(".image").removeClass("active");
    $("#no" + current + "").addClass("active");
  };

  var timer = window.setInterval(timeOut, 5000);

  $(document).on("click", "#next", function() {
    clearInterval(timer);
    current >= data.length ? (current = 1) : current++;
    $(".image").removeClass("active");
    $("#no" + current + "").addClass("active");
    timer = window.setInterval(timeOut, 5000);
  });

  $(document).on("click", "#prev", function() {
    clearInterval(timer);
    current <= 1 ? (current = data.length) : current--;
    $(".image").removeClass("active");
    $("#no" + current + "").addClass("active");
    timer = window.setInterval(timeOut, 5000);
  });

  $(document).on("click", ".thumb", function() {
    clearInterval(timer);
    current = $(this).attr("data-target");
    console.log(current);
    $(".image").removeClass("active");
    $(".image").hide();
    $("#no" + current + "").addClass("active");
    $("#no" + current + "").show();
    $(".image").show();
    timer = window.setInterval(timeOut, 5000);
  });
}
