if (document.getElementById("file")) {
  const fileInput = document.getElementById("file");
  const fileNameOutput = document.getElementById("filename");
  const form = document.getElementById("uploadForm");
  const submitBtn = document.getElementById("submitForm");
  const closeModal = document.getElementById("closeModal");

  form.addEventListener("click", e => {
    if (e.target === closeModal) {
      window.location.href =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
    }
  });

  fileInput.addEventListener("change", () => {
    let fileName = fileInput.files[0].name;
    fileNameOutput.innerHTML = fileName;
  });
}
