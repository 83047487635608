const burger = $("#burger"),
  menu = $("#menu");

burger.on("click", function() {
  burger.toggleClass("active");
  menu.toggleClass("active");
});

function setNavigation() {
  var path = window.location.pathname;
  path = path.replace(/\//g, "");

  $("#menu > div > a").each(function() {
    var href = $(this).attr("href");
    if (path == href) {
      $(this).addClass("active");
    } else {
      $(this).removeClass("active");
    }
  });
}

$(function() {
  setNavigation();
});

$(document).scroll(function() {
    if ($(this).scrollTop() > 200) {
        $("nav").addClass("scrolled");
        $("body").addClass("scrolled");
        $("#sticky").addClass("scrolled");
        $("footer").addClass("scrolled");
  } else {
    $("nav").removeClass("scrolled");
    $("body").removeClass("scrolled");
    $("#sticky").removeClass("scrolled");
    $("footer").removeClass("scrolled");
  }
});

