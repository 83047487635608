var lastScrollTop = window.pageYOffset;

window.addEventListener("scroll", function(){ 
   var st = window.pageYOffset || document.documentElement.scrollTop;
   if (st > lastScrollTop){
      // downscroll code
       if (document.querySelector('.stickies')) { 
           st > 200 ? document.querySelector('.stickies').classList.add('hidden') : null
       }
   } else {
       if (document.querySelector('.stickies')) {
           document.querySelector('.stickies').classList.remove('hidden')
       }
   }
   lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
}, false);