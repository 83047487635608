const gallery = {
  2020: '<img src="assets/img/highlights/2021/tf_2021_1.jpg"><img src="assets/img/highlights/2021/tf_2021_2.jpg"><img src="assets/img/highlights/2021/tf_2021_3.jpg"><img src="assets/img/highlights/2021/tf_2021_4.jpg"><img src="assets/img/highlights/2021/tf_2021_5.jpg"><img src="assets/img/highlights/2021/tf_2021_6.jpg">',
  2019: '<img src="assets/img/highlights/2020/tf_2020_1.jpg"><img src="assets/img/highlights/2020/tf_2020_6.jpg"><img src="assets/img/highlights/2020/tf_2020_2.jpg"><img src="assets/img/highlights/2020/tf_2020_3.jpg"><img src="assets/img/highlights/2020/tf_2020_4.jpg"><img src="assets/img/highlights/2020/tf_2020_5.jpg">',
  2018: '<img src="assets/img/momente/2018/SCHILKE_2019111108483100.jpg"><img src="assets/img/momente/2018/SCHILKE_2019111116204200.jpg"><img src="assets/img/momente/2018/SCHILKE_2019111110024300.jpg"><img src="assets/img/momente/2018/SCHILKE_2019111115242400.jpg"><img src="assets/img/momente/2018/SCHILKE_2019111116173000.jpg"><img src="assets/img/momente/2018/SCHILKE_2019111109212400.jpg">',
  2017: '<img src="assets/img/momente/2017/schilke_2017110609122859_2x.jpg"><img src="assets/img/momente/2017/schilke_2017110609132841_2x.jpg"><img src="assets/img/momente/2017/schilke_2017110609403870_2x.jpg"><img src="assets/img/momente/2017/schilke_2017110609413223_2x.jpg"><img src="assets/img/momente/2017/schilke_2017110613432753_2x.jpg"><img src="assets/img/momente/2017/schilke_2017110615400747-u320812-fr_2x.jpg">',
  2016: '<img src="assets/img/momente/2016/schilke_2016110710455020_2x.jpg"><img src="assets/img/momente/2016/schilke_2016110711274460_2x.jpg"><img src="assets/img/momente/2016/schilke_2016110712272470_2x.jpg"><img src="assets/img/momente/2016/schilke_2016110714163970_2x.jpg"><img src="assets/img/momente/2016/schilke_2016110717020870_2x.jpg"><img src="assets/img/momente/2016/schilke_2016110717261530_2x.jpg">',
  2015: '<img src="assets/img/momente/2015/tf2015_348a_tagung_sohnemann_091115 - kopie - kopie_2x.jpg"><img src="assets/img/momente/2015/tf2015_382_tagung_3d_wieburg_091115 - kopie - kopie_2x.jpg"><img src="assets/img/momente/2015/tf2015_386_tagung_3d_vdvrie_wieburg_kons_091115 - kopie - kopie_2x.jpg"><img src="assets/img/momente/2015/tf2015_411a_tagung_schueller_091115_2x.jpg"><img src="assets/img/momente/2015/tf2015_566_magie_federico_soldati_verheiratete_091115 - kopie - kopie_2x.jpg"><img src="assets/img/momente/2015/tf2015_573a_magie_federico_soldati_091115 - kopie - kopie_2x.jpg">',
  2014: '<img src="assets/img/momente/2014/tf2014_213_trendforum_gaeste_101114_2x.jpg"><img src="assets/img/momente/2014/tf2014_265_trendforum_gaeste_101114_2x.jpg"><img src="assets/img/momente/2014/tf2014_285_trendforum_gaeste_101114_2x.jpg"><img src="assets/img/momente/2014/tf2014_307_trendforum_gaeste_101114_2x.jpg"><img src="assets/img/momente/2014/tf2014_436_trendforum_may_koens_101114_2x.jpg"><img src="assets/img/momente/2014/tf2014_660_trendforum06_pausen_101114_2x.jpg">',
  2013: '<img src="assets/img/momente/2013/tf2013_237_tagung_begruessung_11112013_2x.jpg"><img src="assets/img/momente/2013/tf2013_265_tagung_begruessung_11112013_2x.jpg"><img src="assets/img/momente/2013/tf2013_558_tagung_koehler_ott_11112013_2x.jpg"><img src="assets/img/momente/2013/tf2013_326_tagung_bloching_11112013_2x.jpg"><img src="assets/img/momente/2013/tf2013_745_tagung_oliver_kahn_11112013_2x.jpg"><img src="assets/img/momente/2013/tf2013_852_tagung_kahn_signiert__11112013_2x.jpg">',
  2012: '<img src="assets/img/momente/2012/3586_316_trendforum_precht_121112_2x.jpg"><img src="assets/img/momente/2012/3586_468_trendforum_sanjay_121112_2x.jpg"><img src="assets/img/momente/2012/3586_747_trendforum_sprenger_121112_2x.jpg"><img src="assets/img/momente/2012/3586_827_trendforum_mueller_121112_2x.jpg"><img src="assets/img/momente/2012/3586_858_trendforum_schroeder_121112_2x.jpg"><img src="assets/img/momente/2012/3586_886_trendforum_schroeder_121112_2x.jpg">',
  2011: '<img src="assets/img/momente/2011/tf2011_294_tagung_071111_2x.jpg"><img src="assets/img/momente/2011/tf2011_324_gauck_weiler_kastalio_071111_2x.jpg"><img src="assets/img/momente/2011/tf2011_717_tagung_haeusel_071111_2x.jpg"><img src="assets/img/momente/2011/tf2011_774_tagung_loehr_071111_2x.jpg"><img src="assets/img/momente/2011/tf2011_826_tagung_gaensch_071111_2x.jpg"><img src="assets/img/momente/2011/tf2011_882_tagung_kelly_071111_2x.jpg">',
  2010: '<img src="assets/img/momente/2010/3271_213_spectaris__151110_2x.jpg"><img src="assets/img/momente/2010/3271_226_spectaris__151110_2x.jpg"><img src="assets/img/momente/2010/3271_499_spectaris__151110 - kopie_2x.jpg"><img src="assets/img/momente/2010/3271_536_spectaris__151110_2x.jpg"><img src="assets/img/momente/2010/3271_603_spectaris__151110_2x.jpg"><img src="assets/img/momente/2010/3271_688_spectaris__151110_2x.jpg">',
  2009: '<img src="assets/img/momente/2009/13_trendforum09_120_kons_may_161109_2x.jpg"><img src="assets/img/momente/2009/trendforum06_669_nehberg_161109_2_2x.jpg"><img src="assets/img/momente/2009/trendforum09_050_gaeste_161109_2_2x.jpg"><img src="assets/img/momente/2009/trendforum09_087_kons_may_161109_3_2x.jpg"><img src="assets/img/momente/2009/trendforum09_141_loehr_161109_3_2x.jpg"><img src="assets/img/momente/2009/trendforum2008_202tagung_2x.jpg">',
  2008: '<img src="assets/img/momente/2008/trendforum2008_019empfang_2x.jpg"><img src="assets/img/momente/2008/trendforum2008_099empfang_2x.jpg"><img src="assets/img/momente/2008/trendforum2008_284tagung_2x.jpg"><img src="assets/img/momente/2008/trendforum2008_328tagung_2x.jpg"><img src="assets/img/momente/2008/trendforum2008_518tagung_2x.jpg"><img src="assets/img/momente/2008/trendforum2008_544tagung_2x.jpg">',
  2007: '<img src="assets/img/momente/2007/trendforum07_08_056_2x.jpg"><img src="assets/img/momente/2007/trendforum07_08_129_2x.jpg"><img src="assets/img/momente/2007/trendforum07_08_397_2x.jpg"><img src="assets/img/momente/2007/trendforum07_08_555_2x.jpg"><img src="assets/img/momente/2007/trendforum07_08_594_2x.jpg"><img src="assets/img/momente/2007/trendforum07_08_694_2x.jpg">',
  2006: '<img src="assets/img/momente/2006/2576_512_2x.jpg"><img src="assets/img/momente/2006/2576_600_2x.jpg"><img src="assets/img/momente/2006/2576_692_2x.jpg"><img src="assets/img/momente/2006/2576_748_2x.jpg"><img src="assets/img/momente/2006/trendforum 06_07-351_7_2x.jpg"><img src="assets/img/momente/2006/trendforum 07_07-573_7_2x.jpg">',
  2005: '<img src="assets/img/momente/2005/2576_064_2x.jpg"><img src="assets/img/momente/2005/2576_185_2x.jpg"><img src="assets/img/momente/2005/2576_233_2x.jpg"><img src="assets/img/momente/2005/2576_342_2x.jpg"><img src="assets/img/momente/2005/2576_411_2x.jpg"><img src="assets/img/momente/2005/2576_453_2x.jpg">',
  2004: '<img src="assets/img/momente/2004/20ec9767_2x.jpg"><img src="assets/img/momente/2004/20ec9984_2x.jpg"><img src="assets/img/momente/2004/2576_281_2x.jpg"><img src="assets/img/momente/2004/hamaiel010_10_2x.jpg"><img src="assets/img/momente/2004/hamaiel055_2x.jpg"><img src="assets/img/momente/2004/hamaiel057_2x.jpg">',
  2003: '<img src="assets/img/momente/2003/20ec2250_2x.jpg"><img src="assets/img/momente/2003/20ec2348_2x.jpg"><img src="assets/img/momente/2003/20ec2364_2x.jpg"><img src="assets/img/momente/2003/20ec2393_12_2x.jpg"><img src="assets/img/momente/2003/20ec2498_2x.jpg"><img src="assets/img/momente/2003/20ec22602.jpg">',
  2002: '<img class="bilder2002" src="assets/img/momente/2002/dscn2350_12_2x.jpg"><img class="bilder2002" src="assets/img/momente/2002/dscn2442_12_2x.jpg"><img class="bilder2002" src="assets/img/momente/2002/dscn2500_12_2x.jpg">'
};
if (document.getElementById("tf-logos")) {
  const logoContainer = document.getElementById("tf-logos");
  const logos = logoContainer.querySelectorAll("a");
  const galleryContainer = document.getElementById("gallery");

  for (let i = 0; i < logos.length; i++) {
    logos[i].addEventListener("click", function() {
      const target = this.dataset.target;
      galleryContainer.innerHTML = gallery[target];
    });
  }

  $(function() {
    galleryContainer.innerHTML = gallery[2020];
  });
}

// logos.forEach(logo => {
//     logo.addEventListener('click', function () {
//         const target = this.dataset.target;
//         galleryContainer.innerHTML = gallery[target]
//     })
// })
