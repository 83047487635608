let ticketmodal;
let covidmodal;

const openTicketModal = () => {
	ticketmodal = document.createElement('div')
	const modalInner = document.createElement('div')
	const close = document.createElement('span')
	const onlineButton = document.createElement('a')
	const liveButton = document.createElement('a')

	ticketmodal.classList.add('ticket--modal')
	modalInner.classList.add('ticket--modal-inner')
	onlineButton.classList.add('btn-gelb')
	onlineButton.innerText = "Digital-Tickets bestellen"
	onlineButton.setAttribute('href', '/tickets')
	liveButton.classList.add('btn-gelb')
	liveButton.setAttribute('href', '/anmeldung')
	liveButton.innerText = "Präsenz-Tickets bestellen"
	close.addEventListener('click', () => closeTicketModal(ticketmodal))
	close.innerHTML = "&times;"

	modalInner.appendChild(close)
	modalInner.appendChild(onlineButton)
	modalInner.appendChild(liveButton)

	ticketmodal.appendChild(modalInner)
	
	document.body.appendChild(ticketmodal)
}

const closeTicketModal = (modal) => {
	document.body.removeChild(modal)
 }

const ticketButtons = document.querySelectorAll('[data-ticket]')

ticketButtons.forEach(button => {
	button.addEventListener('click', openTicketModal)
})

window.addEventListener('keyup', () => {
	if (document.querySelector('.ticket--modal')) {
		closeTicketModal(ticketmodal)
	}
})




//============================================
// Covid Modal
//============================================

const openCovidModal = () => {
	covidmodal = document.createElement('div')
	const modalInner = document.createElement('div')

	covidmodal.classList.add('ticket--modal')
	covidmodal.classList.add('covid--modal')
	modalInner.classList.add('ticket--modal-inner')

	modalInner.innerHTML = `<span onclick="closeCovidModal()">&times;</span><p class="covid__info">Die Teilnahme am SPECTARIS-Trendforum ist ausschließlich <strong>geimpften und genesenen Personen</strong> vorbehalten – 2G. <br>

	Bitte legen Sie beim <strong>Check-in</strong> einen entsprechenden <strong>Nachweis</strong> vor (digitales EU-Impfzertifikat, Impfausweis).<br><br>
	
	Zur Sicherheit werden alle Gäste gescannt, damit im Ernstfall eine Kontaktnachverfolgung möglich ist.</p>`

	covidmodal.appendChild(modalInner)
	
	document.body.appendChild(covidmodal)
}

const closeCovidModal = () => {
	document.body.removeChild(covidmodal)
}

 
const covidButtons = document.querySelectorAll('[data-covid]')

covidButtons.forEach(button => {
	button.addEventListener('click', openCovidModal)
})

window.addEventListener('keyup', () => {
	if (document.querySelector('.ticket--modal')) {
		closeTicketModal(covidmodal)
	}
})